import { Text, SimpleGrid, Button, Divider, Title, Image } from '@mantine/core';
import { useTranslation } from "react-i18next";
import { useStyles } from '../app/styles';


/**
 * 
 */
export const Join = () => {
    const { t } = useTranslation();
    const { classes } = useStyles();

    return (
        <>
        <SimpleGrid cols={2}
                  breakpoints={[
                    { maxWidth: 900, cols: 1, spacing: 'sm' },
                  ]}>
            <div>
                <Title order={3}>{t("Join.sessionTitel")}</Title>
                <Text className={classes.text}>{t("Join.sessionText")}</Text>
                <Text weight={'bolder'}>{t("Join.sessionPlace")}</Text>
                <a href='https://docs.google.com/spreadsheets/d/14vrdPcy4yZNPIUF3vBRCnW4EKGXRV2eK3dlyeXi6eAQ/edit?usp=sharing' target='_blank'>
                    {t("Join.sessionBoard")}
                </a>
            </div>
            <div>
                <Image src={"/img/Workshop.jpg"} width="100%" radius='sm'/>
            </div>
        </SimpleGrid>
        <Divider my="sm" />

        <SimpleGrid cols={2}
                  breakpoints={[
                    { maxWidth: 900, cols: 1, spacing: 'sm' },
                  ]}>
            <div>
                <Title order={3}>{t("Join.projekteTitle")}</Title>
                <Text className={classes.text}>{t("Join.projekteText")}</Text>
            </div>
            <div style={{margin: 'auto'}}>
                    <Button component="a" href="/projects">
                    {t("Join.projekteButton")}
                    </Button>
            </div>
        </SimpleGrid>
        <Divider my="sm" />

        <SimpleGrid cols={2}
                  breakpoints={[
                    { maxWidth: 900, cols: 1, spacing: 'sm' },
                  ]}>
            <div>
                <Image src={"/img/codingWE.jpg"} width="100%" radius='sm'/>
            </div>
            <div>
                <Title order={3}>{t("Join.cwTitel")}</Title>
                <Text className={classes.text}>{t("Join.cwText1")}</Text>
                <Text className={classes.text}>{t("Join.cwText2")}</Text>
            </div>
        </SimpleGrid>
        </>
    );
};
