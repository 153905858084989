import { Card, Text, Title, Divider, Image, SimpleGrid, Container} from '@mantine/core';
import { useTranslation } from "react-i18next";
import { useStyles } from '../app/styles';

import people from "./sek_people.json"

export const ShortMemberCard = (userInfo: any) => {
    const { i18n } = useTranslation();
    
    return <Card component='a' href={"/profile?nethz="+userInfo['nethz']} style={{maxWidth: '301px', maxHeight: '349px', margin: 'auto', width: '100%'}}>
            <Title align='center' order={5}>
                { i18n.language === "de" ? userInfo['position_de'] : userInfo['position_en'] }
            </Title>
            <Container style={{width: '100%', maxHeight: '269px', aspectRatio: '1/1'}}>
                <img src={userInfo['image_path']} style={{height: '100%', width: '100%', objectFit: 'scale-down'}}/>
            </Container>
            <Text align='center'>
                {userInfo['name']}
            </Text>
        </Card>;
}

/**
 * The About us page shows what the SEK is and who is behind her
 */
export const AboutUs = () => {
    const { t } = useTranslation();
    const { classes } = useStyles();

    const boardMember = [];
    for (let  member of people.board) {
        boardMember.push(ShortMemberCard(member));
    }

    const commissionMember = [];
    for (let  member of people.member) {
        commissionMember.push(ShortMemberCard(member));
    }
    

    return (
        <>
            <Container size={'sm'}>
                <Image src='img/board_fs22.jpeg' style={{margin: 10}}/>
            </Container>
            <Card sx={() => ({backgroundColor: '#009fe3',
                              color: '#ffffff'})}
                  withBorder={true} shadow="lg">
                <Title order={4}>
                    {t("AboutUs.vorstandtitle")}
                </Title>
                <Text className={classes.text} >{t("AboutUs.vorstandbeschr")}</Text>
                <Divider my="xs" variant='dotted'/>
                <SimpleGrid cols={3}
                  breakpoints={[
                    { maxWidth: 646, cols: 2, spacing: 'sm' },
                    { maxWidth: 443, cols: 1, spacing: 'sm' },
                  ]}>
                    {boardMember}
                </SimpleGrid>
            </Card>
            <Divider my="sm" />
            <Card sx={() => ({backgroundColor: '#009fe3',
                              color: '#ffffff'})}
                  withBorder={true} shadow="lg">
                <Title order={4} >
                    {t("AboutUs.membertitle")}
                </Title>
                <Text className={classes.text} >{t("AboutUs.memberbeschr")}</Text>
                <Divider my="xs" variant='dotted'/>
                <SimpleGrid cols={3}
                  breakpoints={[
                    { maxWidth: 646, cols: 2, spacing: 'sm' },
                    { maxWidth: 443, cols: 1, spacing: 'sm' },
                  ]}>
                    {commissionMember}
                </SimpleGrid>
            </Card>
        </>
    );
};


