import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    text: {
        textAlign: 'justify'
    },
    badge: {
        color: '#fff',
        margin: 2
    },
	container: {
		marginTop: 20,
		marginBottom: 20,
		width: "100%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		minHeight: "64vh",
	},
	content: {
		maxWidth: 970,
		width: "100%",
	}
}));