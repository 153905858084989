export const i18n_english = {
    translation: {
        lang: "en",

        HomePage: {
            title: "Welcome to the SEK",
            aboutUsTitle: "About us",
            aboutUsText: "In the Software Development Committee (SEK), students can develop applications that provide a cool service for students or facilitate the work of volunteers at VSETH. With its continuing education program, the SEK is also the right place to learn new technologies on a modern IT infrastructure in an active community.",   
            join: "Have you always wanted to implement a specific coding project, but don't know where to start? Does your study organization need a new tool for a board game night? Do you want to use your Python skills that you learned during your studies? Then just contact us.",
            joinButton: "Join Us",
            newstitle: "News",
            newsbeschr1: "Coding Sessions", 
            newsbeschr2: "New concept! We meet once a week for a joint coding session. Every Monday evening until the end of the semester we meet in CHN F42 from 6 to 8 pm.",
            newLink1: "Details"

        },
        Navbar: {
            join: "Join us",
            aboutus: "About us",
            startseite: "Home",
            projekte: "Projects",
            links: "Links"
        },
        Join: {
            sessionTitel: "Coding Sessions",
            sessionText: "We meet every Monday evening and thus offer a contact point for all possible questions regarding web development. It doesn't matter if you just had a simple computer science lecture or if you already have your own server. At our coding sessions all people who want to get to know the basics of web programming or even want to take over one of our more difficult projects are welcome.",
            sessionPlace: "Every Monday during the semester in CHN F42 from 6 to 8 pm.",
            sessionBoard: "Check which board members are present.",
            projekteTitle: "Projects",
            projekteText: "From very simple informative websites, constant designs for our websites or even complex processes e.g. in accounting, we have projects in which you can try to participate. There is something for every level. And if you already have an idea of your own but don't quite know where and how to start with, we can help you to set up a concrete project plan.",
            projekteButton: "Overview of Current Projects",
            cwTitel: "Coding Weekend",
            cwText1: "Once a semester we get together with VIS, VMP and AMIV to a comfortable weekend and code. Current projects can be planned and also spontaneous workshops on different topics can be held.",
            cwText2: "Also, as a newcomer you are of course always welcome at the Codingweekend. There will always be some experienced developers with us, with the goal to help you find practical solutions."
        },
        Project: {
            title: "Current Projects",
            text: "Here you can find the current open projects of the SEK. If you are interested in a project, please contact us at "
        },
        Links: {
            title: "Useful Links",
            text: "Here you will find various templates and documentation on technologies we use in the SEK and find useful.",
            desc: "Description",
            link: "Link",
            jump: "Go to Page"
        },
        AboutUs: {
            vorstandtitle: "The SEK Board",
            vorstandbeschr: "The board coordinates the event organization, supports the SEK members in the implementation of their projects, leads the commission and exchanges information with the IT consulting board. You can reach them via sek[ät]vseth.ethz.ch.",
            membertitle: "The SEK members",
            memberbeschr: "SEK members take care of our community projects, can develop and push cool projects within VSETH and student organizations."
        },
        Profile: {
            name: "Name",
            function: "Function",
            task: "Tasks",
            mail: "Mail",
            tech: "Tech Stack",
            projects: "Contributed Projects",
            website: "Website"
        }
    },
};