import { ReactNode } from "react";
import { Route, Routes, Link, useLocation } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { makeVsethTheme, VSETHThemeProvider, VSETHExternalApp, useConfig} from "vseth-canine-ui";
import { useTranslation } from "react-i18next";

import { useStyles } from "./styles";

import { HomePage } from "../pages/HomePage";
import { AboutUs } from "../pages/aboutus";
import { Join } from "../pages/Join"
import { Profile } from "../pages/profile";
import { Links } from "../pages/LinkCollection";
import { Projects } from "../pages/Projects";

/**
 * `App` is the main component of the application it set up the main structural components
 * such as the navigation bar and the Routes.
 */

function App(){
    const { t, i18n } = useTranslation();
    
    const { classes } = useStyles();
    
    const sekMenuNav = [
        { title: t("Navbar.startseite"), href: "/" },
        { title: t("Navbar.aboutus"), href: "/aboutus" },
        { title: t("Navbar.join"), href: "/join" },
        { title: t("Navbar.projekte"), href: "/projects" },
        { title: t("Navbar.links"), href: "/links" },
    ];

    let {data, } = useConfig(
        "https://static.vseth.ethz.ch/assets/vseth-0000-vseth/config.json"
        );

	const theme = makeVsethTheme(data.primaryColor);

    return (
        <>
			<MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
                <VSETHThemeProvider theme={theme}>
                    <VSETHExternalApp
                        selectedLanguage={i18n.language}
                        onLanguageSelect={(lang) => i18n.changeLanguage(lang)}
                        languages={data?.languages}
                        title="SEK"
                        appNav={sekMenuNav}
                        organizationNav={data.externalNav}
                        makeWrapper={(url: string | undefined, child: ReactNode) => (
                            <Link to={url!} style={{ textDecoration: "none", color: "inherit" }}>
                                {child}
                            </Link>
                        )}
                        activeHref={useLocation().pathname}
                        socialMedia={[
                            { type: "instagram", link: "https://www.instagram.com/sek_vseth" },
                            { type: "email", link: "mailto:sek@vseth.ethz.ch" },
                        ]}
                    >
                        <div className={classes.container}>
                            <div className={classes.content}>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={
                                            <HomePage/>
                                        }
                                    />
                                    <Route
                                        path="/aboutus"
                                        element={
                                            <AboutUs/>
                                        }
                                    />
                                    <Route
                                        path="/join"
                                        element={
                                            <Join/>
                                        }
                                    />
                                    <Route
                                        path="/profile/*"
                                        element={
                                            <Profile/>
                                        }
                                    />
                                    <Route
                                        path="/projects"
                                        element={
                                            <Projects/>
                                        }
                                    />
                                    <Route
                                        path="/links"
                                        element={
                                            <Links/>
                                        }
                                    />
                                </Routes>
                            </div>
                        </div>
                    </VSETHExternalApp>
                    </VSETHThemeProvider>
			</MantineProvider>
		</>
    );
};

export default App;
