import { Text, Title, Table } from '@mantine/core';
import linkFile from './sek_links.json';
import { useTranslation } from "react-i18next";
import { useStyles } from '../app/styles';


/**
 * 
 */
export const Links = () => {
    const { t, i18n } = useTranslation();
    const { classes } = useStyles();

    const linkTableValues = [];
    for (let linkValues of linkFile.links){
        linkTableValues.push(
            <tr>
                <td>
                    {i18n.language==='en' ? (linkValues.text_en):(linkValues.text_de)}
                </td>
                <td>
                    <a href={linkValues.link} target='_blank' >{t("Links.jump")}</a>
                </td>
            </tr>
        );
    }


    return (
        <>
            <Title>{t("Links.title")}</Title>
            <Text className={classes.text}>{t("Links.text")}</Text>
            <Table highlightOnHover>
            <thead>
                <tr>
                    <th>{t("Links.desc")}</th>
                    <th>{t("Links.link")}</th>
                </tr>
            </thead>
            <tbody>{linkTableValues}</tbody>
            </Table>
        </>
    );
};
